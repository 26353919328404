<template>
  <div class="flex">
    <div v-for="step in steps" :key="step.name" class="step"
         :class="{first : isFirst(step.value), 'second' : isSecond(step.value), 'third': isThird(step.value)}"
        @click="[ isFirst(step.value) ? $router.push(defaultPath) : null ]">
      <div v-if="step.selected && !step.completed" class="clarity-gradient-orange white border-white">
        <span class="material-icons-round white  mr-3">radio_button_unchecked</span>
        <div>{{ step.name }}: {{ step.title }}</div>
      </div>
      <div v-if="!step.selected && !step.completed" class="bordered">
        <div>{{ step.name }}</div>
      </div>
      <div v-if="!step.selected && step.completed" class="clarity-gradient-light-orange bordered-white">
        <span class="material-icons-round white  mr-3">check_circle</span>
        <div>{{ step.name }}</div>
      </div>
      <div v-if="step.selected && step.completed" class="clarity-gradient-light-orange">
        <span class="material-icons-round white  mr-3">check_circle</span>
        <div>{{ step.name }}: {{ step.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressStepper",
  props: {
    steps: {
      type: Array,
      required: true
    },
    defaultPath: {
      type: String,
      required: false
    }
  },
  setup() {
    const isFirst = (val) => {
      return val === 1
    }
    const isSecond = (val) => {
      return val === 2
    }
    const isThird = (val) => {
      return val === 3
    }

    return {
      isFirst,
      isSecond,
      isThird
    }
  }
}
</script>

<style scoped lang="scss">
.step {
  &.first {
    z-index: 10;
    cursor: pointer;
    > div {
      border-radius: 27px;
      border-right: 1px solid var(--clarity-pure-white) !important;
      border: unset;
    }
  }
  &.second {
    position: relative;
    left: -15px;
    z-index: 8;
    cursor: default;
    > div {
      border: unset;
    }
  }
  &.third {
    position: relative;
    left: -30px;
    z-index: 6;
    cursor: default;
  }

  > div {
    padding: 10px 35px;
    display: flex;
    align-items: center;
    height: 44px;
    border-top-right-radius: 27px;
    border-bottom-right-radius: 27px;

    &.bordered {
      border: 1px solid var(--clarity-light-grey);
      border-left-width: 0;
      &-white {
        border-right: 1px solid var(--clarity-pure-white) !important;
      }
    }
    > span:hover {
      color: var(--clarity-pure-white);
    }
  }
}
</style>