<template>
  <div>
    <DataTable class="rd-table" sortField="planName" :sortOrder="1" :value="holdings">
      <Column header="Holding Name" sortable field="planName">
        <template #body="slotProps">
          <div class="flex flex-column">
            <h3 class="light-blue">
                {{ slotProps.data.planName }}
            </h3>
            <div class="owner-detail">
              <span class=" mr-2">Owner:</span>
              <span v-for="owner in slotProps.data.ownership" :key="owner" >
                <span class="bold mr-1">{{ getOwnerFromUuid(owner) }}</span> <span
                  class="mr-1">({{ $nb(owner.ownership, 'percentage') }})</span>
              </span>
            </div>
          </div>
        </template>
      </Column>
      <Column header="Type" sortable field="selectedInvestmentType" style="width: 220px">
        <template #body="slotProps">
          <h5 class="blue">{{ slotProps.data.selectedInvestmentType }}</h5>
        </template>
      </Column>
      <Column header="Value" field="displayValuation" sortable style="width: 150px">
        <template #body="slotProps">
          <span style="font-size: 18px">{{ $currency('GBP', slotProps.data.displayValuation, 2, false) }}</span>
        </template>
      </Column>
      <Column header="Valuation Date" sortable field="valuationDate" style="width: 150px" class="hide-column">
          <template #body="slotProps">
              <span style="font-size: 18px">{{ $date(slotProps.data.holdings[0].valuationDate, 'DD/MM/YYYY') }}</span>
          </template>
      </Column>
      <Column header="Actions" v-if="canEdit" style="width: 110px">
        <template #body="slotProps">
          <div class="flex">
            <div><span class="material-icons grey ml-3 cursor-pointer" v-tooltip.top="'Edit Holding'"
                       @click="editEntry(findIndexByID(slotProps.data), slotProps.data)">edit</span></div>
            <div><span class="material-icons grey ml-3 cursor-pointer" v-tooltip.top="'Delete Holding'"
                       @click="removeRow(findIndexByID(slotProps.data))">delete_forever</span></div>
          </div>
        </template>
      </Column>
      <template #empty>
        <div class="flex flex-column align-items-center" v-if="!liabilities">
          <h4>No holdings currently available.</h4>
          <Button class="clarity-btn clarity-gradient-orange" @click="setMode('asset'); $router.push('/investments/selfmanaged/add')">Add
            a New Asset
          </Button>
        </div>
        <div class="flex flex-column align-items-center" v-else>
            <h4>No holdings currently available.</h4>
            <Button class="clarity-btn clarity-gradient-orange" @click="selectSpecificProduct">Add
                a New Liability
            </Button>
        </div>
      </template>
    </DataTable>

    <Dialog :visible="deleteModal" class="delete-dialog">
      <div class="flex justify-content-between modal-header">
        <h3 class="blue">Confirm Delete</h3>
        <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span>
        </Button>
      </div>
      <div class="message">Are you sure you want to delete this holding ({{holdings[deleteHolding].planName}})?</div>
      <div class="button-group">
        <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
        <submit-button @submitted="confirmDelete" :loading="loadingState" button-text="Confirm Action" loading-text="Removing Holding..." style="padding: 5px;"></submit-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import api from '@/core/services/api';
import { toDrupalRequest } from '@/core/helpers';
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";


export default {
  name: "SMHValuationTable",
  components: {SubmitButton},
  props: {
    editable: {
      type: Boolean,
      required: false
    },
    liabilities: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const canEdit = ref(props.editable ?? true)
    const liabilities = ref(props.liabilities)
    const deleteModal = ref(false)
    const deleteHolding = ref(null)
    const holdings = computed(() => {
        if(liabilities.value){
            return store.getters.selfManagedLiabilities.map((liability) => {
              liability.displayValuation = getValuation(liability.holdings)
              return liability
            })
        }else{
            return store.getters.selfManagedHoldings.map((asset) => {
              asset.displayValuation = getValuation(asset.holdings)
              return asset
            })
        }
    })

    const loadingState = computed(() => store.getters.isLoading('RemoveSelfManaged'))

    onMounted(() => {
      store.dispatch('getPeopleOptions')
    })

    const editEntry = (idx, data) => {

      if(liabilities.value){
          setMode('liability');
      }else{
          setMode('asset');
      }

      const productCategoryId = store.getters.productTypeFromField('label', data.selectedCategory)
      const product = store.getters.productFromLabel(productCategoryId.id, data.selectedInvestmentType)

      store.dispatch('setNewSelfManagedType', productCategoryId.id)
      store.dispatch('updateSelectedProduct', product.id)

      store.commit('setSMHField', {field: 'assetId', value: data.assetId})
      store.commit('setSMHField', {field: 'liabilityId', value: data.liabilityId})
      store.commit('setSMHField', {field: 'planName', value: data.planName})
      store.commit('setSMHField', {field: 'ownership', value: data.ownership})
      data.filteredExtendedDetails.forEach(detail => {
        store.commit('setSMHField', {field: detail.id, value: detail.value})
      })
      store.commit('setHolding', data.holdings)

      store.dispatch('editValuationHolding', true)
      store.dispatch('editValuationHoldingIndex', idx)

      store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 2, completed: 1})
      router.push('/investments/selfmanaged/add/details')
    }

    const removeRow = (row) => {
      deleteModal.value = true
      deleteHolding.value = row
    }

    const getValuation = (holdings) => {
      let sum = 0.00
      holdings.forEach(holding => {
          let thePrice = holding.unitPrice;
          if(holding.currency != 'GBP'){
              // if display is not in GBP we need to convert the currency.
              const currencyOptions = computed(() => store.state.selections.currencyOptions);
              if(currencyOptions.value) {
                  const conversionCurrency = currencyOptions.value.find(obj => {
                      return obj.currencyCode === holding.currency
                  });
                  if (conversionCurrency) {
                      thePrice = thePrice / conversionCurrency.conversionrate;
                  }
              }
          }
          sum += (thePrice * holding.units)
      })

      return sum
    }

    const getOwnerFromUuid = (owner) => {
      return store.getters.personFromUuid(owner.owner).name
    }


    const closeModal = () => {
      deleteModal.value = false
      deleteHolding.value = null
    }

    const confirmDelete = async () => {

        store.dispatch('setLoading', {id: 'RemoveSelfManaged', state: true})

        if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }

        // pass the feed ID as the param when making the delet request.
        const endpoint = liabilities.value ? '/selfmanaged/liabilities/' + holdings.value[deleteHolding.value].liabilityId : '/selfmanaged/assets/' + holdings.value[deleteHolding.value].assetId;
        const response = await api.delete(`${toDrupalRequest(endpoint)}`)

        // check response was a success
        if (response === undefined) {
            await store.dispatch('addNotification', {
                severity: 'error',
                summary: 'Delete Self Managed Holding',
                detail: `Unable to delete the data feed.`
            })
            store.dispatch('setLoading', {id: 'RemoveSelfManaged', state: false})
            closeModal()
        } else {
            // on success dispatch the delete action accordingly
            liabilities.value ? store.dispatch('deleteLiability', deleteHolding.value) : store.dispatch('deleteHolding', deleteHolding.value)
            // confirm deletion
            store.dispatch('addNotification', {
                severity: 'success',
                summary: 'Self Managed Holding Deleted',
                detail: ``,
                life: 4000
            })
            store.dispatch('setLoading', {id: 'RemoveSelfManaged', state: false})
            store.commit('setPolicyLoading', true)
            store.commit('setPortfolioLoading', true)
            store.dispatch('getPortfolioOptions')
            store.dispatch('getPolicyOptions')
            closeModal()
        }

    }
    const setMode = (mode) => {
        store.commit('setSelfManagedMode', mode)
    }

    const selectSpecificProduct = () => {
        setMode('liability');
        store.dispatch('setNewSelfManagedType', store.getters.getParentOfProduct(77).id)
        store.dispatch('updateSelectedProduct', 77)
        store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 2, completed: 1})
        router.push('/investments/selfmanaged/add/details')
    }

    const findIndexByID = (data) => {
        return holdings.value.findIndex((item) => {
            if(liabilities.value) {
                return item.liabilityId === data.liabilityId;
            }else{
                return item.assetId === data.assetId;
            }
        });
    }

    return {
      canEdit,
      holdings,
      editEntry,
      removeRow,
      getValuation,
      getOwnerFromUuid,
      activeModal: computed(() => store.getters.currentActiveModal),
      deleteModal,
      deleteHolding,
      closeModal,
      confirmDelete,
      loadingState,
      setMode,
      selectSpecificProduct,
      findIndexByID
    }
  }
}
</script>

<style scoped>
button:not(.clarity-close-modal-btn){
  min-width: 300px;
  max-width: 30%;
  justify-content: center;
  margin: 5px 5px 5px 0;
  padding: 15px 45px;
}
.clarity-close-modal-btn {
  background: none;
  border: 1px solid var(--clarity-light-grey);
  padding: 0;
  height: 32px !important;
  width: 32px !important;
  justify-content: center;
}
.owner-detail {
    color: var(--clarity-grey)
}

</style>
