<template>
  <div class="p-5 flex flex-column" data-cy="self-managed">
    <div>
      <ContentCardFull>
        <template #title>
          <CardTitle>
            <template #title><h2>Self Managed Holdings</h2></template>
          </CardTitle>
        </template>
        <template #content-full>
          <ProgressStepper v-if="$route.path.includes('/selfmanaged/add')" :steps="stepOptions" :defaultPath="'/investments/selfmanaged/add'" />

          <TabSelfManaged  v-if="isRootPage" />

          <router-view></router-view>

        </template>
      </ContentCardFull>
    </div>

    <SpacerH/>

    <HelpCard mode="investments"/>

    <NewSelfManagedSelection v-show="showModal === 'selectSelfManagedSubProduct'" />
    <NewSelfManagedSplitModal v-show="showModal === 'newSelfManagedSplitModal'" />
    <SelfManagedHoldingRecordModal v-show="showModal === 'SelfManagedHoldingRecordModal'" :mode="modalMode" :origin="modalOrigin"/>
  </div>
</template>

<script>
import ContentCardFull from "@/components/common/ContentCardFull";
import CardTitle from "@/components/common/CardTitle";
import HelpCard from "@/components/common/HelpCard";
import {computed, onMounted, ref} from 'vue'
import SpacerH from "@/components/common/layout/SpacerH";
import {useStore} from "vuex";
import TabSelfManaged from "@/components/selfManaged/TabSelfManaged";
import ProgressStepper from "@/components/common/ProgressStepper";
import NewSelfManagedSelection from "@/components/modals/NewSelfManagedSelection";
import NewSelfManagedSplitModal from "@/components/modals/NewSelfManagedSplitModal";
import SelfManagedHoldingRecordModal from "@/components/modals/SelfManagedHoldingRecordModal";
import {useRoute} from "vue-router";

export default {
  name: "SelfManaged",
  components: {
    NewSelfManagedSplitModal,
    NewSelfManagedSelection,
    SelfManagedHoldingRecordModal,
    CardTitle,
    ContentCardFull,
    HelpCard,
    TabSelfManaged,
    ProgressStepper,
    SpacerH
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    onMounted(() => {
      // @TODO: force failure/empty array for adding new holding flow
      store.dispatch('fetchSelfManagedHoldings')
      store.dispatch('fetchSelfManagedLiabilities')
      store.dispatch('fetchProductTypes')
      store.dispatch('fetchLiabilityTypes')
      store.dispatch("getCurrencyOptions")
      store.dispatch("fetchFundLinks")
    })

    const selfManagedHelpCards = ref([
      {
        image: require("@/assets/img/dashboard/StickManAtDesk.png"),
        title: "Online Valuation Tour",
        blurb: "Find out more about the features of out online valuation tool.",
        buttonLink: "",
        buttonText: "Start the Tour"
      },
      {
        image: require("@/assets/img/dashboard/StickManSignPostFamily.png"),
        title: "Guided Tour",
        blurb: "Watch our Guided Tour video to become familiar with the clarity website.",
        buttonLink: "",
        buttonText: "Update Feeds"
      },
      {
        image: require("@/assets/img/dashboard/StickManCouple.png"),
        title: "Fund provider data feeds",
        blurb: "View or update your data feeds for your current clarity investments.",
        buttonLink: "/investments/datafeeds",
        buttonText: "Update Feeds"
      },
    ])

    const showModal = computed(() => store.getters.currentActiveModal);
    const modalMode = computed(() => store.getters.activeModalMode);
    const modalOrigin = computed(() => store.getters.activeModalOrigin);

    const isRootPage = computed(() => {
      return route.path === '/investments/selfmanaged'
    })

    return {
      selfManagedHelpCards,
      // assets: computed(() => store.getters.selfManagedHoldings()), // could have id passed to it?
      assets: computed(() => store.getters.selfManagedHoldings),
      stepOptions: computed(() => store.getters.getSteps('selfManagedOptions')),
      showModal,
      modalMode,
      modalOrigin,
      isRootPage
    }
  }
}
</script>

<style scoped>

h3 {
  padding-bottom: 30px;
}

button {
  min-width: 300px;
  max-width: 30%;
  justify-content: center;
  margin: 5px 5px 5px 0;
  padding: 15px 45px;
}
</style>
