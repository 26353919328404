<template>
  <Modal id="newSelfManagedRecord" :width="`60%`">
    <template #body>
      <ContentCardFull>
        <template #title>
          <div class="flex flex-column title">
            <div class="flex justify-content-between align-items-center">
              <h2>Adding a new <span v-if="assetMode === 'liability'">value</span><span v-else>asset</span> to the policy/wrapper</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </div>
        </template>
        <template #content-full>
          <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
            <p class="body-text" v-if="!showSimple">Please note that all the Assets with the <span class="material-icons blue">lock</span> icon have pre-filled data provided by clarity. If you want to apply any amends, please click on the ‘Edit Details’ button.</p>

            <div class="section">
              <h3>Holding Details</h3>
              <div class="full-width flex flex-wrap">
                <div class="title-input half">
                  <label for=""><span v-if="assetMode !== 'liability'">Holding </span>Name:</label>
                  <AutoComplete
                      class="full-width"
                      v-model="formData.fund"
                      field="label"
                      optionLabel="value"
                      :suggestions="filteredCountries"
                      @complete="search"
                      @item-select="updateFundCode"
                      placeholder="Please Specify..."
                      :disabled="formData.usingClarityData"
                      v-if="assetMode !== 'liability' && !showSimple && !formData.usingClarityData"
                  >
                    <template #item="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.item.value }}</div>
                      </div>
                    </template>
                  </AutoComplete>
                  <div class="input-container" v-else-if="assetMode !== 'liability' && !showSimple">
                      <span class="p-input-icon-left">
                        <InputText v-model="formData.fund.value" :disabled="formData.usingClarityData" style="width: 100%;" />
                      </span>
                      <span class="clarity-icon material-icons blue">lock</span>
                      <div class="clarity-clear" @click="unlinkClarityData" ><span class="material-icons hand-pointer grey mr-2" style="font-size: 1.2em">edit</span>Edit Details</div>
                  </div>
                  <InputText v-else v-model="formData.fund" class="full-width" placeholder="Please Specify..." required />
                </div>

                <div class="title-input half" v-if="assetMode !== 'liability' && !showSimple">
                  <label for="">Fund Code:</label>
                  <div class="flex">
                    <Dropdown v-model="formData.fundCodeType" :options="fundCodeOptions" optionLabel="label"
                              optionValue="value" class="mr-3" @before-show="stopScroll" @before-hide="allowScroll"/>
                    <InputText v-model="formData.fundCode" class="full-width" placeholder="Please Specify..." required :disabled="formData.usingClarityData" />
                  </div>
                </div>
              </div>
            </div>

            <div class="section">
              <h3>Current Value</h3>
              <div v-if="showSimple">
                  <div class="full-width flex flex-wrap">
                      <div class="title-input half">
                          <label for="">Valuation Date:</label>
                          <span class="wrapper">
                      <Calendar id="basic" v-model="formData.valuationDate" dateFormat="dd/mm/yy" autocomplete="off"
                                panel-class="calendar-valuationdate" ref="datepicker">
                      </Calendar>
                      <span class="material-icons-round calendar-icon grey" @click="triggerDatepicker">today</span>
                    </span>
                      </div>
                      <div class="title-input half">
                          <label for="">Value:</label>
                          <div class="flex">
                              <Dropdown v-model="formData.currency" :options="currencyOptions" optionLabel="currencyCode"
                                        optionValue="currencyCode" class="mr-3" @before-show="stopScroll"
                                        @before-hide="allowScroll"/>
                              <InputNumber v-if="assetMode !== 'liability'" v-model="formData.units" class="full-width" placeholder="Please Specify..." required
                                           @update:modelValue="calcPrice" :min="0" :maxFractionDigits="4" />
                              <InputNumber v-else v-model="formData.units" class="full-width" placeholder="Please Specify..." required
                                           @update:modelValue="calcPrice" :min="0" :maxFractionDigits="4" prefix="-" />
                          </div>
                      </div>
                  </div>
              </div>
              <div v-else>
                <div class="full-width flex flex-wrap">
                  <div class="title-input half">
                    <label for="">Valuation Date:</label>
                    <span class="wrapper">
                      <Calendar id="basic" v-model="formData.valuationDate" dateFormat="dd/mm/yy" autocomplete="off"
                                panel-class="calendar-valuationdate" ref="datepicker">
                      </Calendar>
                      <span class="material-icons-round calendar-icon grey" @click="triggerDatepicker">today</span>
                    </span>
                  </div>
                  <div class="title-input half">
                    <label for="">Units:</label>
                    <InputNumber v-if="assetMode !== 'liability'" v-model="formData.units" class="full-width" placeholder="Please Specify..." required
                                 @update:modelValue="calcPrice" :min="0" :maxFractionDigits="4" />
                  </div>
                </div>

                <div class="full-width flex flex-wrap">
                  <div class="title-input half">
                    <label for="">Unit Price:</label>
                    <div class="flex">
                      <Dropdown v-model="formData.currency" :options="currencyOptions" optionLabel="currencyCode"
                                optionValue="currencyCode" class="mr-3" @before-show="stopScroll"
                                @before-hide="allowScroll"/>
                      <InputNumber v-model="formData.price" class="full-width" placeholder="Please Specify..." required
                                   mode="currency" :currency="formData.currency" @update:modelValue="calcPrice"
                                   :maxFractionDigits="6" :disabled="formData.usingClarityData || assetMode === 'liability'" />
                    </div>

                  </div>
                  <div class="title-input half">
                    <label for="">Value:</label>
                    <InputNumber v-model="formData.value" class="full-width" placeholder="Please Specify..."
                                 mode="currency" :currency="formData.currency" disabled />
                  </div>
                </div>
              </div>
            </div>

            <div class="section" v-if="assetMode !== 'liability'">
              <h3>Asset Allocation</h3>
              <div class="full-width flex flex-wrap">
                <div class="title-input half">
                  <label for="" v-if="formData.allocation === 'custom'" style="color: #fff !important;">.</label>
                  <Dropdown v-model="formData.allocation" :options="allocationOptions" optionLabel="label" placeholder="Please Select"
                            optionValue="value" @before-show="stopScroll" @before-hide="allowScroll" :disabled="formData.usingClarityData"/>
                </div>
                <div class="title-input half" v-if="formData.allocation === 'custom'">
                  <label for="">Sector:</label>
                  <Dropdown v-model="sectorAllocation" :options="sectorOptions" optionLabel="label"
                            optionValue="value" @before-show="stopScroll" @before-hide="allowScroll" :disabled="formData.usingClarityData"/>
                </div>
              </div>
            </div>

            <div v-if="formData.allocation === 'custom'">
              <div class="allocation-group">
                <div class="flex flex-wrap justify-content-between title align-items-center">
                  <card-title class="with-totaliser">
                    <template #title>
                      <h3>Asset Allocation Values</h3>
                      <span class="material-icons-round grey"
                            v-tooltip.top="'Asset Allocation values (Fixed Interest, Equity and Property) must total 100%'">info</span>
                    </template>
                    <template #content>
                    <span class="small-text">
                        Total target value:
                        <span :class="[totalTally('asset') ? 'tally' : 'no-tally']">{{
                            assetTotal
                          }}% / <b>100%</b></span>
                    </span>
                    </template>
                  </card-title>
                </div>
                <div class="filter-group flex-wrap row">
                  <div class="filter-set">
                    <label>Fixed Interest:</label>
                    <div class="p-inputgroup">
                      <InputText name="assetFixedInt" v-model="assetAllocation.fixedinterest"
                                 placeholder="Please specify..."
                                 @blur="v$.assetAllocation.fixedinterest.$touch"
                                 :disabled="formData.usingClarityData"/>
                      <span class="p-inputgroup-addon">%</span>
                    </div>
                    <div v-if="v$.assetAllocation.fixedinterest.$error">Please ensure these values total 100%
                    </div>
                  </div>
                  <div class="filter-set">
                    <label>Equity:</label>
                    <div class="p-inputgroup">
                      <InputText v-model="assetAllocation.equity" placeholder="Please specify..."
                                 @blur="v$.assetAllocation.equity.$touch"
                                 :disabled="formData.usingClarityData"/>
                      <span class="p-inputgroup-addon">%</span>
                    </div>
                    <div v-if="v$.assetAllocation.equity.$error">Please ensure these values total 100%</div>
                  </div>
                  <div class="filter-set">
                    <label>Other:</label>
                    <div class="p-inputgroup">
                      <InputText v-model="assetAllocation.property" placeholder="Please specify..."
                                 @blur="v$.assetAllocation.property.$touch"
                                 :disabled="formData.usingClarityData"/>
                      <span class="p-inputgroup-addon">%</span>
                    </div>
                    <div v-if="v$.assetAllocation.property.$error">Please ensure these values total 100%
                    </div>
                  </div>
                  <div class="filter-set">
                    <label>Cash:</label>
                    <div class="p-inputgroup">
                      <InputText v-model="assetAllocation.cash" placeholder="Please specify..."
                                 @blur="v$.assetAllocation.cash.$touch"
                                 :disabled="formData.usingClarityData"/>
                      <span class="p-inputgroup-addon">%</span>
                    </div>
                    <div v-if="v$.assetAllocation.cash.$error">Please ensure these values total 100%
                    </div>
                  </div>
                </div>
              </div>
              <div class="allocation-group">
                <div class="flex flex-wrap justify-content-between title align-items-center">
                  <card-title class="with-totaliser">
                    <template #title><h3>Equity Allocation Values</h3>
                      <span class="material-icons-round grey"
                            v-tooltip.top="'Equity allocation values must total 100%'">info</span>
                    </template>
                    <template #content>
                  <span class="small-text">Total target value: <span
                      :class="[totalTally('equity') ? 'tally' : 'no-tally']">{{
                      equityTotal
                    }}% / <b>100%</b></span></span>
                    </template>
                  </card-title>
                </div>
                <div class="filter-group long">
                  <div class="flex flex-wrap filter-top-row">
                    <div class="filter-set">
                      <label>UK:</label>
                      <div class="p-inputgroup">
                        <InputText v-model="equityAllocation.ukequity" placeholder="Please specify..."
                                   @blur="v$.equityAllocation.ukequity.$touch"
                                   :disabled="formData.usingClarityData"/>
                        <span class="p-inputgroup-addon">%</span>
                      </div>
                      <div v-if="v$.equityAllocation.ukequity.$error">Please ensure these values total 100%
                      </div>
                    </div>
                    <div class="filter-set">
                      <label>US:</label>
                      <div class="p-inputgroup">
                        <InputText v-model="equityAllocation.usequity" placeholder="Please specify..."
                                   @blur="v$.equityAllocation.usequity.$touch"
                                   :disabled="formData.usingClarityData"/>
                        <span class="p-inputgroup-addon">%</span>
                      </div>
                      <div v-if="v$.equityAllocation.usequity.$error">Please ensure these values total 100%
                      </div>
                    </div>
                    <div class="filter-set">
                      <label>European:</label>
                      <div class="p-inputgroup">
                        <InputText v-model="equityAllocation.euroequity" placeholder="Please specify..."
                                   @blur="v$.equityAllocation.euroequity.$touch"
                                   :disabled="formData.usingClarityData"/>
                        <span class="p-inputgroup-addon">%</span>
                      </div>
                      <div v-if="v$.equityAllocation.euroequity.$error">Please ensure these values total 100%
                      </div>
                    </div>
                    <div class="filter-set">
                      <label>Asia Pacific:</label>
                      <div class="p-inputgroup">
                        <InputText v-model="equityAllocation.fareastequity" placeholder="Please specify..."
                                   @blur="v$.equityAllocation.fareastequity.$touch"
                                   :disabled="formData.usingClarityData"/>
                        <span class="p-inputgroup-addon">%</span>
                      </div>
                      <div v-if="v$.equityAllocation.fareastequity.$error">Please ensure these values total 100%
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-wrap filter-second-row">
                    <div class="filter-set">
                      <label>Japan:</label>
                      <div class="p-inputgroup">
                        <InputText v-model="equityAllocation.japanequity" placeholder="Please specify..."
                                   @blur="v$.equityAllocation.japanequity.$touch"
                                   :disabled="formData.usingClarityData"/>
                        <span class="p-inputgroup-addon">%</span>
                      </div>
                      <div v-if="v$.equityAllocation.japanequity.$error">Please ensure these values total 100%
                      </div>
                    </div>
                    <div class="filter-set">
                      <label>Emerging Markets:</label>
                      <div class="p-inputgroup">
                        <InputText v-model="equityAllocation.otherequity" placeholder="Please specify..."
                                   @blur="v$.equityAllocation.otherequity.$touch"
                                   :disabled="formData.usingClarityData"/>
                        <span class="p-inputgroup-addon">%</span>
                      </div>
                      <div v-if="v$.equityAllocation.otherequity.$error">Please ensure these values total 100%</div>
                    </div>
                    <div class="filter-set">
                      <label>Specialist:</label>
                      <div class="p-inputgroup">
                        <InputText
                            v-model="equityAllocation.specialistequity" placeholder="Please specify..."
                            @blur="v$.equityAllocation.specialistequity.$touch"
                            :disabled="formData.usingClarityData"/>
                        <span class="p-inputgroup-addon">%</span>
                      </div>
                      <div v-if="v$.equityAllocation.specialistequity.$error">Please ensure these values total 100%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ScrollPanel>

          <div class="flex justify-content-between mt-3" style="width: 100%">
            <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
            <Button class="clarity-btn clarity-gradient-orange" :disabled="!checkData()" @click="confirmDetails">
                Confirm and Continue
            </Button>
          </div>
        </template>
      </ContentCardFull>
    </template>
  </Modal>
</template>

<script>
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import ContentCardFull from "@/components/common/ContentCardFull"
import CardTitle from "@/components/common/CardTitle";
import Modal from "@/components/common/Modal";
import {useStore} from "vuex";
import {computed, onBeforeUnmount, reactive, ref, watch} from "vue";
import {decimal, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {inject} from "vue";

export default {
  name: "SelfManagedHoldingRecordModal",
  components: {
    ContentCardFull,
    Modal,
    CardTitle
  },
  props: ['mode', 'origin'],
  setup(props) {
    const store = useStore()
    const bus = inject('bus')
    const assetMode = computed(() => store.state.selfManaged.mode)
    const today = new Date()
    const index = computed(() => store.getters.getEditSMHoldingRecordIndex)
    const isEditing = ref()
    const test = ref()
    const selectedSMH = computed(() => store.getters.getEditSMHoldingRecord)
    const valuationSMH = computed(() => store.getters.selfManagedHoldings)

    const datepicker = ref(null);
    const triggerDatepicker = () => {
        datepicker.value.onButtonClick();
    }

    watch(() => props, (value) => {
      if (value.mode && value.mode === 'edit') {
        isEditing.value = true

        if (value.origin === 'HoldingTable' && selectedSMH.value) {
          Object.keys(selectedSMH.value).forEach(field => {
            switch (field) {
              case 'unitPrice':
                formData['price'] = selectedSMH.value[field]
                break
              case 'name':
                formData['fund'] = selectedSMH.value[field]
                break
              case 'code':
                formData['fundCode'] = selectedSMH.value[field]
                break
              case 'codeType':
                  formData['fundCodeType'] = selectedSMH.value[field]
                  break
                case 'units':
                  formData['units'] = Math.abs(selectedSMH.value[field])
                  break
              case 'assetAllocation':
                if(selectedSMH.value[field])  {
                    // we have asset allocation data.
                    // do a conversion here.
                    convertAllocation(true, selectedSMH.value[field]);
                }else{
                    formData[field] = selectedSMH.value[field]
                }
                break
              default:
                formData[field] = selectedSMH.value[field]
            }
          })
          calcPrice()
        } else if (value.origin === 'ValuationTable' && valuationSMH.value) {
          Object.keys(valuationSMH.value[index.value]['holdings'][0]).forEach(field => {
            formData[field] = valuationSMH.value[index.value]['holdings'][0][field]
          })
        }
      }
    }, {deep: true})

    const calcPrice = () => {
        if(assetMode.value === 'liability') {
            formData.value = ref(formData.price * (0-formData.units))
        }else{
            formData.value = ref(formData.price * formData.units)
        }
    }

    const cur = computed(() => store.getters.currencySelected)

    const formData = reactive({
      fund: "",
      fundCodeType: "ISIN",
      fundCode: null,
      valuationDate: today,
      units: null,
      currency: cur.value,
      price: 1,
      value: null,
      allocation: null,
      usingClarityData: false,
      name: null
    })

    const fundCodeOptions = ref([
      {label: 'ISIN', value: 'ISIN'},
      {label: 'SEDOL', value: 'SEDOL'},
      {label: 'CITI', value: 'CITI'},
      {label: 'TIDM (EPIC)', value: 'TIDM (EPIC)'},
      {label: 'Ticker Symbol', value: 'Ticker'},
    ])

    const updateFundCode = async (event) => {
      formData.fundCode = event.value.id

      if(event.value.price === 'N/A'){
          // load the price data for this ticker.
          const response = await api.get(`${toDrupalRequest('/stock-data/eod?symbols='+event.value.id)}`)
          if(response[0]){
              formData.price = parseFloat(response[0]["eod_price"]);
              // formData.units = // user will actually set this!!!
              formData.value = formData.price * formData.units
              formData.usingClarityData = !!event.value.id
              if(event.value.id.includes('.LSE')){
                  formData.currency = 'GBP'
              }else{
                  formData.currency = 'USD'
              }
              formData.fundCodeType = 'Ticker'
          }

      }else {
          if (event.value.pricedate) {
              formData.valuationDate = new Date(event.value.pricedate.date.slice(0, 10))
          }
          formData.price = parseFloat(event.value.price)
          // formData.units = // user will actually set this!!!
          formData.value = formData.price * formData.units
          formData.usingClarityData = !!event.value.id
      }

      // needs to happen first or other values may be lost
      formData.allocation = 'custom'

      sectorAllocation.value = event.value.sector

      const totalEquity = event.value.ukequity + event.value.usequity + event.value.euroequity + event.value.fareastequity + event.value.japanequity + event.value.otherequity + event.value.specialistequity;
      setSingleAssetField('equity', totalEquity)
      Object.keys(event.value).forEach((key) => {
          if (['cash', 'property', 'fixedinterest'].includes(key)) {
              setSingleAssetField(key, event.value[key])
          }
          if (['usequity', 'ukequity', 'specialistequity', 'otherequity', 'japanequity', 'fareastequity', 'euroequity'].includes(key) && totalEquity > 0) {
              setSingleEquityField(key, (event.value[key] / totalEquity) * 100)
          }
      })

    }

    const allocationOptions = ref([
      {label: 'UK Equity', value: 'uk_equity'},
      {label: 'US Equity', value: 'us_equity'},
      {label: 'European Equity', value: 'european_equity'},
      {label: 'Asia Pacific Equity', value: 'asia_pacific_equity'},
      {label: 'Japan Equity', value: 'japan_equity'},
      {label: 'Emerging Markets/Other Equity', value: 'emerging_equity'},
      {label: 'Specialist Equity', value: 'specialist_equity'},
      {label: 'Fixed Interest', value: 'fixed_int'},
      {label: 'Other (Including Property)', value: 'other'},
      {label: 'Cash', value: 'cash'},
      {label: 'Custom', value: 'custom'}
    ])

    const sectorOptions = ref([
      {label: 'Cash', value: 'Cash'},
      {label: 'Fixed Interest', value: 'Fixed Interest'},
      {label: 'UK', value: 'UK'},
      {label: 'US', value: 'US'},
      {label: 'Europe', value: 'Europe'},
      {label: 'AsiaPacific', value: 'AsiaPacific'},
      {label: 'Japan', value: 'Japan'},
      {label: 'Emerging Markets', value: 'Emerging Markets'},
      {label: 'Specialist', value: 'Specialist'},
      {label: 'Global', value: 'Global'},
      {label: 'Multi-Asset', value: 'Multi-Asset'},
      {label: 'Commodity', value: 'Commodity'},
      {label: 'Other', value: 'Other'},
      {label: 'Property', value: 'Property'},
    ])

    watch(() =>  formData.allocation, (newAllocation) => {
        setAllocation(newAllocation)
    }, {deep: true})

    const setSingleEquityField = (field, value) => {
      Object.keys(equityAllocation).forEach(key => {
        if (key === field) {equityAllocation[key] = value}
      })
    }

    const setSingleAssetField = (field, value) => {
      Object.keys(assetAllocation).forEach(key => {
        if (key === field) {assetAllocation[key] = value}
      })
    }

    const setAllocation = (selection) => {
      if(selection !== 'custom'){
          resetAllocation();
      }
      switch (selection) {
        case 'uk_equity':
          setSingleAssetField('equity', 100)
          setSingleEquityField('ukequity', 100)
          sectorAllocation.value = 'UK'
          break
        case 'us_equity':
          setSingleAssetField('equity', 100)
          setSingleEquityField('usequity', 100)
          sectorAllocation.value = 'US'
          break
        case 'european_equity':
          setSingleAssetField('equity', 100)
          setSingleEquityField('euroequity', 100)
          sectorAllocation.value = 'Europe'
          break
        case 'asia_pacific_equity':
          setSingleAssetField('equity', 100)
          setSingleEquityField('fareastequity', 100)
          sectorAllocation.value = 'AsiaPacific'
          break
        case 'japan_equity':
          setSingleAssetField('equity', 100)
          setSingleEquityField('japanequity', 100)
          sectorAllocation.value = 'Japan'
          break
        case 'emerging_equity':
          setSingleAssetField('equity', 100)
          setSingleEquityField('otherequity', 100)
          sectorAllocation.value = 'Emerging Markets'
          break
        case 'specialist_equity':
          setSingleAssetField('equity', 100)
          setSingleEquityField('specialistequity', 100)
          sectorAllocation.value = 'Specialist'
          break
        case 'fixed_int':
          setSingleAssetField('fixedinterest', 100)
          sectorAllocation.value = 'Fixed Interest'
          break
        case 'other':
          setSingleAssetField('property', 100)
          sectorAllocation.value = 'Property'
          break
        case 'cash':
          setSingleAssetField('cash', 100)
          sectorAllocation.value = 'Cash'
          break
        case 'custom':
          setSingleAssetField('', 100)
          break
      }
    }


    const sectorAllocation = ref(null)

    const assetAllocation = reactive({
      fixedinterest: 0.00,
      equity: 0.00,
      property: 0.00,
      cash: 0.00
    })

    const equityAllocation = reactive({
      ukequity: 0.00,
      usequity: 0.00,
      euroequity: 0.00,
      fareastequity: 0.00,
      japanequity: 0.00,
      otherequity: 0.00,
      specialistequity: 0.00
    })

    const resetAllocation = () => {
        assetAllocation.fixedinterest = 0.00;
        assetAllocation.equity = 0.00;
        assetAllocation.property = 0.00;
        assetAllocation.cash = 0.00;

        equityAllocation.ukequity = 0.00;
        equityAllocation.usequity = 0.00;
        equityAllocation.euroequity = 0.00;
        equityAllocation.fareastequity = 0.00;
        equityAllocation.japanequity = 0.00;
        equityAllocation.otherequity = 0.00;
        equityAllocation.specialistequity = 0.00;
        sectorAllocation.value = null;
    }

    const assetTotal = computed(() => {
      let total = parseFloat(assetAllocation.fixedinterest) + parseFloat(assetAllocation.equity) + parseFloat(assetAllocation.property) + parseFloat(assetAllocation.cash)
      return isNaN(total) ? 0.00 : total.toFixed(2)
    })

    const equityTotal = computed(() => {
      let total = parseFloat(equityAllocation.ukequity) + parseFloat(equityAllocation.usequity) + parseFloat(equityAllocation.euroequity) + parseFloat(equityAllocation.fareastequity) + parseFloat(equityAllocation.japanequity) + parseFloat(equityAllocation.otherequity) + parseFloat(equityAllocation.specialistequity)
      return isNaN(total) ? 0.00 : total.toFixed(2)
    })

    const totalTally = (total) => {
      switch (total) {
        case 'equity':
            if(assetTotal.value === '100.00' && assetAllocation.equity == 0){
                return true;
            }else {
                return '100.00' === equityTotal.value
            }
        case 'asset' :
          return '100.00' === assetTotal.value
      }
    }

    const checkBenchmarkTally = computed(() => {
      return totalTally('equity') && totalTally('asset')
    })

    const rules = {
      assetAllocation: {
        fixedinterest: {required, decimal},
        equity: {required, decimal},
        property: {required, decimal},
        cash: {required, decimal},
      },
      equityAllocation: {
        ukequity: {required, decimal},
        usequity: {required, decimal},
        euroequity: {required, decimal},
        fareastequity: {required, decimal},
        japanequity: {required, decimal},
        otherequity: {required, decimal},
        specialistequity: {required, decimal}
      }
    }

    const v$ = useVuelidate(rules, {assetAllocation: assetAllocation, equityAllocation: equityAllocation})


    const closeModal = () => {
      store.dispatch('setActiveModal')
      store.dispatch('setEditSMHoldingRecord', null)
      resetForm()
    }

    const resetForm = () => {
      resetAllocation()
      v$.value.$reset()
      formData.fund = null
      formData.fundCodeType = "ISIN"
      formData.fundCode = null
      formData.valuationDate = null
      formData.units = null
      formData.price = 1
      formData.value = null
      formData.allocation = null
      formData.valuationDate = today
      formData.usingClarityData = false
    }

    const convertToHoldingFormat = () => {
        let allocation = null;
        if(formData.allocation){
            allocation = convertAllocation(false, null)
        }

        let name = '';
        if(typeof formData.fund === "string"){
            name = formData.fund
        }else{
            name = formData.fund.label
        }

        let units = formData.units;
        if(assetMode.value === 'liability') {
            units = 0-units;
        }

        return {
            "name": name,
            "usingClarityData":formData.usingClarityData,
            "code":formData.fundCode,
            "codeType":formData.fundCodeType,
            "valuationDate":formData.valuationDate,
            "units":units,
            "unitPrice":formData.price,
            "currency":formData.currency,
            "assetAllocation": allocation
        };
    }

    const convertAllocation = (write, data) => {
        if(write){
            // direction 1 - convert existing data into this format.
            if(data){

                // what do we do with sector and allocation?
                formData.allocation = "custom"
                sectorAllocation.value = data.sector

                // we need to populate the data.
                assetAllocation.cash = data.cash
                assetAllocation.fixedinterest = data.fixedInterest
                assetAllocation.property = data.property
                const sumEquity = data.ukEquity+data.usEquity+data.euroEquity+data.fareastEquity+data.japanEquity+data.otherEquity+data.specialistEquity;
                assetAllocation.equity = sumEquity
                if(sumEquity > 0) {
                    equityAllocation.ukequity = (data.ukEquity / sumEquity) * 100
                    equityAllocation.usequity = (data.usEquity / sumEquity) * 100
                    equityAllocation.euroequity = (data.euroEquity / sumEquity) * 100
                    equityAllocation.fareastequity = (data.fareastEquity / sumEquity) * 100
                    equityAllocation.japanequity = (data.japanEquity / sumEquity) * 100
                    equityAllocation.otherequity = (data.otherEquity / sumEquity) * 100
                    equityAllocation.specialistequity = (data.specialistEquity / sumEquity) * 100
                }else{
                    equityAllocation.ukequity = 0
                    equityAllocation.usequity = 0
                    equityAllocation.euroequity = 0
                    equityAllocation.fareastequity = 0
                    equityAllocation.japanequity = 0
                    equityAllocation.otherequity = 0
                    equityAllocation.specialistequity = 0
                }

            }else{
                formData.allocation = null;
                sectorAllocation.value = null;
            }

        }else{
            // convert this allocation data into the DB expected format.
            if(assetAllocation.equity > 0){
                return {
                    "cash": assetAllocation.cash,
                    "fixedInterest": assetAllocation.fixedinterest,
                    "property": assetAllocation.property,
                    "ukEquity": assetAllocation.equity*(equityAllocation.ukequity/100),
                    "usEquity": assetAllocation.equity*(equityAllocation.usequity/100),
                    "euroEquity": assetAllocation.equity*(equityAllocation.euroequity/100),
                    "fareastEquity": assetAllocation.equity*(equityAllocation.fareastequity/100),
                    "japanEquity": assetAllocation.equity*(equityAllocation.japanequity/100),
                    "otherEquity": assetAllocation.equity*(equityAllocation.otherequity/100),
                    "specialistEquity": assetAllocation.equity*(equityAllocation.specialistequity/100),
                    "sector": sectorAllocation.value
                }
            }else{
                return {
                    "cash": assetAllocation.cash,
                    "fixedInterest": assetAllocation.fixedinterest,
                    "property": assetAllocation.property,
                    "ukEquity": 0,
                    "usEquity": 0,
                    "euroEquity": 0,
                    "fareastEquity": 0,
                    "japanEquity": 0,
                    "otherEquity": 0,
                    "specialistEquity": 0,
                    "sector": sectorAllocation.value
                }
            }

        }
    }

    const confirmDetails = () => {
      const data = convertToHoldingFormat();
      store.dispatch('saveHolding', {
        data: data,
        index: isEditing.value === true ? store.getters.getEditSMHoldingRecordIndex : null
      })
      closeModal()
    }

    bus.on('resetSMHForm', () => {
      resetForm()
    })

    const checkData = () => {
      // @TODO: need in also require custom selection
      return !!(formData.fund && formData.valuationDate && (formData.units !== 0) && formData.price && (checkBenchmarkTally.value || formData.allocation === null) )
    }

    const stopScroll = () => {
      bus.emit('stopScroll')
    }

    const allowScroll = () => {
      bus.emit('allowScroll')
    }

    const getLocale = computed(() => store.getters.currencyFromCode(formData.currency))

    const fundlinkOptions = computed(() => store.getters.fundlinks)
    const fundOptions = ref()
    const lookupFund = (val) => {
      fundOptions.value = fundlinkOptions.value.filter(fund => {
        return fund.searchtext.includes(val.target.value)
      })
    }

    const filteredCountries = ref();
    const search = (event) => {
      setTimeout(() => {
        if (event.query.trim().length < 2) {
          filteredCountries.value = [];
        } else {
          filteredCountries.value = fundlinkOptions.value.filter((country) => {
            return country.searchtext.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    }

    const selectedProduct = computed(() => store.getters.getSelectedProductObject)
    const parentType = computed(() => {
        if(selectedProduct.value) {
            return store.getters.getParentOfProduct(selectedProduct.value.id);
        }else{
            return false;
        }
    })

    const showSimple = computed(() => {
        if(assetMode.value === 'liability'){
            return true
        }else {
            if (parentType.value && parentType.value.label === 'Cash' || parentType.value.label === 'Property' || parentType.value.label === 'Protection') {
                return true
            } else {
                // check for specific product types.
                if (selectedProduct.value && selectedProduct.value.id === 75) {
                    return true
                }
                return false
            }
        }
    })

    const unlinkClarityData = () => {
        formData.usingClarityData = false
    }

    onBeforeUnmount(() => {
        bus.off('resetSMHForm')
    });

    return {
      assetMode,
      search,
      filteredCountries,
      closeModal,
      checkData,
      formData,
      confirmDetails,
      allocationOptions,
      fundCodeOptions,
      sectorOptions,
      currencyOptions: computed(() => store.getters.currencyOptions),
      v$,
      equityAllocation,
      assetAllocation,
      sectorAllocation,
      assetTotal,
      equityTotal,
      totalTally,
      stopScroll,
      allowScroll,
      getLocale,
      today,
      isEditing,
      selectedSMH,
      test,
      index,
      calcPrice,
      lookupFund,
      fundOptions: computed(() => fundOptions.value),
      fundlinkOptions,
      updateFundCode,
      selectedProduct,
      showSimple,
      parentType,
      datepicker,
      triggerDatepicker,
      unlinkClarityData
    }
  }
}
</script>

<style scoped lang="scss">
div:deep(.modal-close) {
  display: none;
}

.title {
  padding: 30px;
  padding-bottom: 0;
}

.body {
  font-size: 18px;
}

.section {
  padding: 15px 0
}

.title-input {
  display: flex;
  flex-direction: column;
  min-width: 33%;
  margin-bottom: 10px;
}

.title-input label {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--clarity-dark-grey);
}

.title-input:nth-of-type(1) {
  padding-right: 20px;
}

.title-input:nth-of-type(2) {
  padding-left: 20px;
}

.half {
  width: 50%;
}

.clarity-btn {
  padding: 15px 45px;
  justify-content: center;
}

.allocation-group {
  margin-bottom: 30px;

  .title {
    padding: 15px 0 0 0;
  }
}


.modal:deep(.card-title) {
  padding: unset;
  height: unset;
  align-items: unset;
}

.modal:deep(.card-title h3) {
  margin-right: 15px;
}

.modal:deep(.card-title::before) {
  height: unset;
  width: unset;
  margin-right: unset;
  background-color: unset !important;
}

.with-totaliser {
  width: 100%;
}

.with-totaliser > .card-title-wrapper {
  justify-content: space-between;
}

.with-totaliser:deep(.title-content) {
  padding-right: 0;
}

.filter-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;

  &.row {
    flex-direction: row;
  }
}

.filter-set {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  width: 25%;
}

.filter-set label {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-content: center;
}

.wrapper {
  position: relative;
}

.wrapper:deep(.p-inputwrapper) {
  width: 100%;
}

.calendar-icon {
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
}

.tally {
  color: var(--clarity-green);
  font-weight: bold;
}

.no-tally {
  color: var(--clarity-red);
  font-weight: bold;
}

.filter-top-row,
.filter-second-row {
  margin-bottom: 15px;
}
.modal:deep(.p-autocomplete-input.p-inputtext.p-component) {
  width: 100% !important;
}
.p-input-icon-left {
  width: 100%;
}
.input-container {
  position: relative;
}
.clarity-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
}
.clarity-clear {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--clarity-grey);
}

@media only screen and (max-width: 1366px) {
  .half {
    width: 100%;
  }
  .title-input:nth-of-type(2) {
    padding-left: 0px;
  }
  .title-input:nth-of-type(1) {
    padding-right: 0px;
  }
  .filter-group .filter-set {
    width: 50%;
  }
}

</style>
