<template>
  <Modal id="newSelfManagedHoldingModal" :width="`60%`">
    <template #body>
      <ContentCardFull>
        <template #title>
          <div class="flex flex-column title">
            <div class="flex justify-content-between align-items-center">
              <h2>Choose which kind of product you want to add to your valuation summary</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </div>
        </template>
        <template #content-full>
          <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
            <div>
              <div class="flex flex-wrap">
                <div v-for="option in options" :key="option.id" class="product-option-wrapper" @click="updateSelectedProduct(option.id)">
                  <div class="product-option">
                    <Checkbox :id="option.id" name="selectedProduct" v-model="selectedProduct" :value="option.id"
                              @change="updateSelectedProduct(option.id)"/>
                    <h3 :for="option.id" class="ml-3 mt-2 light-blue">{{ option.label }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </ScrollPanel>
          <div class="flex justify-content-between mt-3" style="width: 100%">
            <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
            <Button class="clarity-btn clarity-gradient-orange" @click="confirmSelection" :disabled="!selectedInOptions">Confirm and Continue</Button>
          </div>
        </template>
      </ContentCardFull>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/common/Modal';
import ContentCardFull from '@/components/common/ContentCardFull'
import {useStore} from "vuex";
import {computed, ref, watch} from "vue";
import {useRouter} from "vue-router";

export default {
  components: {
    Modal,
    ContentCardFull
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const options = ref([])

    const closeModal = () => {
      store.dispatch('setActiveModal')
    }

    const type = computed(() => store.getters.selectedNewSelfManagedType)

    const selectedProduct = computed(() => store.getters.selectedProduct)

    const selectedInOptions = computed(() => {
        return options.value.find(x => x.id == selectedProduct.value)
    })

    const updateSelectedProduct = (selectedId) => store.dispatch('updateSelectedProduct', selectedId)

    const confirmSelection = () => {
      store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 2, completed: 1})
      closeModal()
      router.push('/investments/selfmanaged/add/details');
    }

    watch(type, newType => {
      options.value = store.getters.productsForType(newType)
    })

    return {
      closeModal,
      options,
      selectedProduct,
      updateSelectedProduct,
      confirmSelection,
      selectedInOptions
    }
  }
}
</script>
<style scoped>
.title {
  padding: 30px;
  padding-bottom: 0;
}

div:deep(.modal-close) {
  display: none;
}

.product-option-wrapper {
    flex: 50%;
    padding:8px;
    min-height: 125px;
}

.product-option {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: var(--clarity-snow-grey);
  border-radius: 27px;
  border: 2px solid var(--clarity-light-grey);
  padding: 15px;
  cursor: pointer;
}
.clarity-btn {
  padding: 15px 45px;
  justify-content: center;
}
div:deep(.p-checkbox-box) {
  border-radius: 50px !important;
}
@media only screen and (max-width: 1366px)  {
    .product-option-wrapper {
        flex: 100%;
        min-height: 70px;
    }
}
</style>
