<template>
  <div class="flex flex-column">
    <div class="content-full">
      <h3>Introduction</h3>
      <p class="body">From this area you can add assets and liabilities not included in our automated data
        feeds. These plans can then be included in online reporting and also be included in any advice or
        investment management services. You may be able to transfer some of the assets to one of the providers
        that benefits from an automated valuation feed, If you would like to discuss this further please contact
        your usual adviser or
        <a class="light-blue underline" href="mailto:enquiries@clarityglobal.com">enquiries@clarityglobal.com</a>.
      </p>
    </div>

    <div class="content-full mb-3">
      <h3>Assets</h3>
      <div class="flex mb-3">
        <div class="flex flex-column">
          <p class="body">Your manually added assets are detailed below. You can update the valuation/asset allocation
            details from this page as well as adding new assets to include in the online valuation and reporting
            services available on our website. Anything you enter on this page will be made available to your adviser
            so the more information you can provide the better.</p>
          <p class="body">We offer priced feeds for an extensive number of funds and stocks so in some cases you will
            only need to enter the units you hold, the remaining information will keep the valuation up to date with the
            latest fund prices.</p>
        </div>
        <div class="flex flex-column mx-3">


        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-column mb-3">
    <div class="content-full mb-3">
      <div class="my-3 sm-button-group">
        <Button class="clarity-btn clarity-gradient-orange" @click="setMode('asset'); $router.push('/investments/selfmanaged/add')">Add a New Asset</Button>
        <Button class="clarity-btn clarity-gradient-orange mr-3" @click="selectSpecificProduct('isa')">Add a Stocks & Shares ISA</Button>
        <Button class="clarity-btn clarity-gradient-orange mr-3 hide-button" @click="selectSpecificProduct('property')">Add an Investment Property</Button>
        <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/investments/openbanking')">Add a Bank Account</Button>
      </div>
    </div>
  </div>

  <SMHValuationTable :editable="true" :liabilities="false"/>

  <div class="flex flex-column">

    <div class="content-full">
      <h3>Liabilities</h3>
      <div class="flex mb-3">
          <div class="flex flex-column">
            <p class="body">Your manually added Liabilities are listed below, you can add/update your assets using the inputs
              below. <br> Mortgages added to the list below can be associated with a property to show the net value of the
              property on your valuation reports.</p>
            <p class="body">All data entered below is made available to your adviser so the more information you can provide
              the better. We suggest updating the values ahead of any meetings with your adviser.</p>
          </div>
          <div class="flex flex-column mx-3 hide-small">
              <Button class="clarity-btn clarity-gradient-orange" @click="selectSpecificProduct('liability')">Add a New Liability</Button>
          </div>
      </div>
      <div class="show-small">
          <div class="flex justify-content-center mb-3" >
              <Button class="clarity-btn clarity-gradient-orange" @click="selectSpecificProduct('liability')">Add a New Liability</Button>
          </div>
      </div>
    </div>

    <SMHValuationTable :editable="true" :liabilities="true"/>

  </div>
</template>
<script>
import SMHValuationTable from "@/components/selfManaged/SMHValuationTable";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {onMounted} from "vue";

export default {
  name: 'TabSelfManaged',
  components: {
    SMHValuationTable
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    onMounted(() => {
      store.dispatch('resetSMHStep', 2)
    })

    const selectSpecificProduct = (productId) => {
      if(productId === 'liability'){
          setMode('liability');
          store.dispatch('setNewSelfManagedType', store.getters.getParentOfProduct(77).id)
          store.dispatch('updateSelectedProduct', 77)
      }else {
          const useId = productId === 'isa' ? 38 : 65; // 65 = investment property
          setMode('asset');
          store.dispatch('setNewSelfManagedType', store.getters.getParentOfProduct(useId).id)
          store.dispatch('updateSelectedProduct', useId)
      }
      store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 2, completed: 1})
      router.push('/investments/selfmanaged/add/details')
    }

    const setMode = (mode) => {
        store.commit('setSelfManagedMode', mode)
    }

    return {
      selectSpecificProduct,
      setMode
    }
  }
}
</script>
<style scoped>
.content-full {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

h3 {
  padding-bottom: 30px;
}

.blank-button-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--clarity-snow-grey);
  border-radius: 27px;
  padding: 60px 20px;
}

button {
  min-width: 300px;
  max-width: 30%;
  justify-content: center;
  margin: 5px 5px 5px 0;
  padding: 15px 45px;
}

.hide-small {
    display: block !important;
}
.show-small {
    display: none !important;
}

@media only screen and (max-width: 1366px)  {
    .hide-button {
        display: none;
    }
    .hide-small {
        display: none !important;
    }
    .show-small {
        display: block !important;
    }
}
@media only screen and (max-width: 768px) {
    .sm-button-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
