<template>
  <Modal id="newSelfManageSplit" :width="`40%`">
    <template #body>
      <ContentCardFull>
        <template #title>
          <div class="flex flex-column title">
            <div class="flex justify-content-between align-items-center">
              <h2>Ownership: customise percentages</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </div>
        </template>
        <template #content-full>
          <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
            <DataTable class="rd-table" :value="peopleOptions">
              <Column header="Ownership" field="name" sortable style="min-width: 50%; width: 50%;">
                <template #body="slotProps">
                  <div class="name-field">{{ slotProps.data.name }}</div>
                </template>
              </Column>
              <Column header="Percentage" style="min-width: 30%; width: 30%">
                <template #body="slotProps">
                  <InputNumber placeholder="Please specify percentage" class="full-width"
                               v-model="slotProps.data.percentage"
                               :maxFractionDigits="2"
                  />
                </template>
              </Column>
            </DataTable>
            <div>
              <p class="body bold">Overview:</p>
              <p class="body">Please check the percentage you selected:</p>
              <ul>
                <li v-for="(person, idx) in selectedPeople" :key="idx" class="body">
                  <span class="bold">{{ person.name }}:</span> <span> {{ $nb(person.percentage, 'percentage') }}</span>
                </li>
              </ul>
            </div>
          </ScrollPanel>
          <div class="flex justify-content-between mt-5">
            <Button class="clarity-btn clarity-gradient-grey" @click="resetForm">Dismiss</Button>
            <Button class="clarity-btn clarity-gradient-orange" :disabled="disableButton"  @click="confirmSplit">Confirm and Continue</Button>
          </div>
        </template>
      </ContentCardFull>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import ContentCardFull from '@/components/common/ContentCardFull'
import {useStore} from "vuex";
import {computed, watch, ref, onMounted,} from "vue";

export default {
  name: "NewSelfManagedSplitModal",
  components: {
    Modal,
    ContentCardFull
  },
  setup() {
    const store = useStore()
    const peopleOptions = ref([])


    const closeModal = () => {
      store.dispatch('setActiveModal')
    }


    const resetForm = () => {
      setPeople(store.state.selections.peopleOptions ?? [])
      closeModal()
    }

    const setPeople = (peopleArray) => {
      peopleOptions.value = peopleArray.map(person => {
        return {...person, percentage: 0}
      })
    }

    onMounted(() => {
      setPeople(store.state.selections.peopleOptions ?? [])
    })

    const people = computed(() => store.state.selections.peopleOptions)

    watch(people, (val) => {
      setPeople(val)
    })

    const selectedPeople = computed(() => {
        return peopleOptions.value.filter((person) => person.percentage > 0);
    })


    const confirmSplit = () => {
      store.dispatch('setSelfManagedSplit', selectedPeople.value)
      resetForm()
    }

    const disableButton = computed(() => {
      let tally = 0
      peopleOptions.value.forEach(person => tally+=person.percentage)

      return tally !== 100;
    })

    return {
      closeModal,
      peopleOptions,
      confirmSplit,
      disableButton,
      resetForm,
      selectedPeople
    }
  }
}
</script>

<style scoped>
div:deep(.modal-close) {
  display: none;
}

.title {
  padding: 30px;
  padding-bottom: 0;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

.name-field {
  color: var(--clarity-blue);
  font-weight: bold;
  font-size: 18px;
  padding-left: 20px;
}

li.body::marker {
  content: none;
}

:deep(.p-inputtext) {
  color: var(--clarity-blue);
  font-weight: bold;
  font-size: 18px;
  padding-left: 20px;
}

.clarity-btn {
  padding: 15px 45px;
  justify-content: center;
}
</style>
